
.no-started-live {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  user-select: none;
  background-color: #070932;
  display: flex;
  padding: 20px;

  .live-script {
    box-sizing: border-box;
    flex: 6;
    font-size: 14px;
    color: #fff;
    background-color: #0c0e3f;

    .container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .head-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100%;
        font-size: 24px;
        .title {
          margin: 0 24px;
          margin-top: -2px;
        }
        .decorate {
          width: 41px;
          height: 15px;
          vertical-align: middle;
        }
      }
      .content {
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
        overflow: hidden;
        .live-ing-script {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          width: 85%;
          height: 100%;
          font-size: 24px;
          line-height: 72px;
          letter-spacing: 1px;
          word-break: break-all;
          overflow: hidden;
          .text {
            transform: translateY(-30px);
          }
        }
        .empty {
          position: absolute;
          top: 40%;
          font-size: 20px;
          color: #aaa;
        }
      }
    }
  }

  .live-main {
    position: relative;
    box-sizing: border-box;
    width: 562px;
    height: 100%;
    margin: 0 20px;
    overflow: hidden;
    padding: 12px 10px;
    background: url("../../../assets/image/default-live-no-start-bg.png") no-repeat;
    background-size: cover;

    &.radius {
      border-radius: 20px;
    }

    .container {
      .head-user-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #fff;

        .left-user {
          position: relative;
          box-sizing: border-box;
          width: calc(222px - 53px);
          outline: none;

          .user-box {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 24px;

            .avatar {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              cursor: pointer;
            }

            .user-info-box {
              flex: 1;
              margin-left: 5px;
              text-align: center;
              font-size: 15px;
              font-weight: 700;
              overflow: hidden;

              .username {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }

        .right-audience {
          display: flex;
          align-items: center;
          height: 48px;

          .people-sum {
            min-width: 60px;
            height: 36px;
            margin: 0 5px;
            line-height: 36px;
            text-align: center;
            background-color: #000;
            opacity: 0.3;
            border-radius: 18px;
          }
        }
      }
    }
  }

  .live-other-info {
    box-sizing: border-box;
    flex: 4;

    .container {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .popularity {
        padding-block: 20px;
        margin-bottom: 20px;
        background-color: #0c0e3f;
      }

      .bullet-chat {
        flex: 1;
        background-color: #0c0e3f;
      }

      .live-popularity {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        color: #fff;
        font-size: 18px;

        .icon {
          font-size: 22px;
          line-height: 28px;
          margin-right: 9px;
        }

        .item .hint-box,
        .item .right {
          width: 116px;
        }

        .time-box {
          display: flex;
          justify-content: space-between;
          width: 80%;
          .item {
            display: flex;
            .time {
              margin-top: 10px;
              color: #ff3e6c;
            }
            .not-start-time {
              color: #fff;
            }
          }
        }

        .popularity {
          display: flex;
          width: 80%;
          .right-popularity {
            flex: 1;
            .info-list {
              margin-top: 20px;
              .item {
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                margin-bottom: 25px;
                .num-box {
                  display: flex;
                  align-items: center;
                  margin-top: 10px;
                  font-size: 24px;
                  color: #4b45ff;
                  .rise,
                  .go-down {
                    margin-left: 15px;
                    font-size: 25px;
                    color: #ff3e6c;
                  }
                  .go-down {
                    color: #12c1c1;
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      .bullet-chat {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        color: #fff;
        padding: 20px 0;
        .title-box {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          padding-left: 20px;
          font-size: 18px;
          .decorate {
            width: 31px;
            height: 11px;
            margin-right: 10px;
            vertical-align: middle;
          }
        }
        .content {
          flex: 1;
          overflow: hidden;
          .list {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .container {
              box-sizing: border-box;
              width: 75%;
              overflow: hidden;
            }
            .item {
              box-sizing: border-box;
              display: flex;
              width: fit-content;
              padding-right: 10px;
              margin-bottom: 15px;
              font-size: 16px;
              color: #fff;
              background-color: rgba(0, 0, 0, 0.3);
              border-radius: 15px;
              .avatar-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                margin-right: 9px;
                border-radius: 50%;
                overflow: hidden;
                background-color: #fff;
                vertical-align: middle;
                .avatar-img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  vertical-align: middle;
                }
              }
              .desc {
                flex: 1;
                overflow: hidden;
                line-height: 27px;
                word-break: break-all;
                .username {
                  color: #4b45ff;
                }
              }
            }
          }
        }
      }
      ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        margin-right: 0px !important;
        margin-bottom: 0px !important;

        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }
  }
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
